import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [3,4,5,6,7];

export const dictionary = {
		"/": [8],
		"/auth": [~9,[2]],
		"/auth/sign-in": [~10,[2]],
		"/auth/sign-up": [~11,[2]],
		"/auth/verify-email": [~12,[2]],
		"/auth/verify-session": [~13,[2]],
		"/auth/verify-session/[sessionId]": [~14,[2]],
		"/core/actions": [17,[3]],
		"/core/audit": [~18,[3]],
		"/core/controls": [~19,[3]],
		"/core/controls/[id]": [~20,[3]],
		"/core/dashboard": [~21,[3]],
		"/core/datasets": [~22,[3]],
		"/core/(assignments)/documents": [~15,[3]],
		"/core/frameworks": [~23,[3]],
		"/core/frameworks/[id]": [~24,[3]],
		"/core/models": [~25,[3]],
		"/core/models/[id]": [26,[3,4]],
		"/core/models/[id]/[ver]": [27,[3,4,5]],
		"/core/models/[id]/[ver]/assessments": [28,[3,4,5]],
		"/core/models/[id]/[ver]/reports": [29,[3,4,5]],
		"/core/models/[id]/[ver]/reports/explainability": [31,[3,4,5]],
		"/core/models/[id]/[ver]/reports/[rep]": [30,[3,4,5]],
		"/core/questionnaires": [~32,[3]],
		"/core/(assignments)/tests": [~16,[3]],
		"/core/vendors": [~33,[3]],
		"/core/vendors/[id]": [~34,[3]],
		"/join-org": [~35],
		"/org": [36,[6]],
		"/org/settings": [~37,[6]],
		"/org/switch-org": [~38,[6]],
		"/public-vendor": [~39],
		"/radar/applications": [~40,[7]],
		"/radar/dashboard": [~41,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';